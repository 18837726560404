.projects {
  display: flex;
  flex-direction: column;
  line-height: 1.8rem;
  justify-content: center;
  max-width: 58rem;
  margin: 2rem auto 2rem auto;
  @include theme('color', $text-color);

  a {
    @include theme('color', $text-color);
  }

  h1 {
    margin-top: 0;
    font-family: "Open Sans", sans-serif;
    font-weight: 800;
  }

  h2 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0;

    a {
      @include theme('color', $text-color);
      text-decoration: none;
    }
  }

  p {
    margin: 0.5rem 0;
  }
}

.projects h1.projects--headline {
  margin: 1rem 0 !important
}

.projects--list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 60rem;
  margin: 0;
  padding: 0;
}

.projects--item {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 1rem 0;

  @include theme('background-color', $list-background);
  @include theme('color', $list-color);

  &:last-child {
    margin: 1rem 0 0 0;
  }
}

.projects--item--link {
  background-color: white;
  line-height: 0;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
}

.projects--item--image {
  width: 11rem;
  height: 11rem;
  flex-grow: 0;
  flex-shrink: 0;
}

.projects--item--description {
  padding: 0 1rem 1rem 1rem;
}

.projects--item--tech {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 1rem;

  li {
    list-style: none;
    padding: 0.5rem;
    font-size: 0.8rem;
    line-height: 0.8rem;
    margin-right: 0.1rem;
    font-weight: 500;
    white-space: nowrap;
    border-radius: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;

    @include theme('background-color', $code-color);

    @include theme('color', $text-color);
  }

}

@media (max-width: 42rem) {
  .projects--item {
    flex-direction: column;
    margin: 0 0 2rem 0;

    &:last-child {
      margin: 0;
    }
  }

  .projects--item--link {
    text-align: center;
  }

  .projects--item--description {
    padding: 1rem;

    h2 {
      margin-top: 0;
    }
  }
}