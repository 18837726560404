.index-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 60rem;
  margin: 0 auto;
  padding: 0;
}

.index-list--item {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 1rem;

  a {
    display: flex;
    text-decoration: none;
    @include theme('color', $list-color);
  }

  @include theme('background-color', $list-background);
  @include theme('color', $list-color);
}

.index-list--image {
  width: 11rem;
  height: 11rem;
  border-radius: 0.5rem;
  flex-grow: 0;
  flex-shrink: 0;
  object-fit: cover;
  overflow: hidden;
}

.index-list--description {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  height: 11rem;
}

.index-list--description--headline {
  @include theme('color', $list-color);
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.index-list--description--text {
  display: flex;

  p {
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    line-height: 1.5rem;
  }
}

.index-list--description--meta {
  display: flex;
  font-size: 0.8rem;
  font-weight: 500;
  flex: 1;
  margin-top: 0.6rem;
  align-items: flex-end;
}

.index-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 60rem;
  margin: 0 auto;
  padding: 0 1rem;
}

.index-pagination {
  text-align: center;
  margin-bottom: 2rem;
  flex-grow: 1;
  padding-left: 16px;
  @include theme('color', $text-color);

  .previous,
  .next {
    text-decoration: none;
    @include theme('color', $text-color);
  }
}

@media (max-width: 35rem) {
  .home {
    margin-top: 2rem;
  }
}

@media (max-width: 50rem) {
  .index-footer {
    min-width: 21rem;
    padding: 0;
  }

  .index-list {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    padding: 0;
    min-width: 21rem;
  }

  .index-list--item {
    width: 100%;
    height: auto;
    margin: 1rem 0 2rem 0;

    a {
      flex-direction: column;
      max-width: 100%;
    }
  }

  .index-list--description {
    height: auto;
  }

  .index-list--description--headline {
    white-space: unset;
    overflow: auto;
    text-overflow: unset;
    margin-top: 0.5rem;
  }

  .index-list--image {
    width: 100%;
    height: 14rem;
  }
}