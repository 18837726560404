article {
  h1.headline {
    font-size: 2.5rem;
    line-height: 3rem;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0.5rem;
    margin-top: 0;
  }

  .head-without-image {
  }

  a {
    @include theme("color", $text-color);
  }

  img.header-image {
    width: calc(100% + 4rem);
    margin: 0 -2rem;
    border-radius: 1rem;
  }

  .copyright {
    font-size: 0.8rem;

    p {
      margin: 0 0 0.5rem 0;
      text-align: right;
    }
  }

  .meta {
    font-size: 1rem;
    font-family: "Open Sans", sans-serif;
    font-weight: 200;
    margin-bottom: 2rem;
    display: inline-block;
  }
}

.post {
  .highlight {
    padding: 0.5rem 1rem;
    margin: 0 -1rem;
    overflow-y: scroll;
    border-radius: 0.5rem;
    @include theme("background-color", $code-color);
  }

  img {
    max-width: 100%;
    border-radius: 5px;

    &:hover {
      border-radius: 0px;
    }
  }

  blockquote {
    @include theme("border-left-color", $border);
    border-left-width: 0.5rem;
    border-left-style: solid;
    margin-left: 0;
    padding-left: 0.5rem;
  }
}

.author {
  width: calc(100% + 8rem);
  background-color: $author-background;
  color: $author-color;
  margin: 4rem -4rem 0 -4rem;
  padding: 2rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
}

.author--photo {
  display: flex;
  flex-direction: column;
}

.author--name {
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 0.2rem;
  text-align: center;
}

.author--role {
  text-align: center;
}

.author--portrait {
  max-width: 9rem;
  border-radius: 0.5rem;
}

.author--description {
  max-width: 40rem;
  padding-left: 2rem;
  font-size: 1.2rem;
  line-height: 1.7rem;

  p:first-child {
    margin-top: 0;
  }

  a {
    color: $author-color;
  }
}

footer {
  background-color: $footer-background;
  margin: 0 -4rem -1rem -4rem;
  padding: 2rem;
  text-align: center;
  font-size: 1.5rem;

  a {
    color: $footer-color;
    padding: 0 1rem;
    font-size: 0;
    text-decoration: none;

    i {
      font-size: 2rem;
    }
  }
}

@media (max-width: 30rem) {
  article {
    img.header-image {
      width: calc(100% + 2rem);
      margin: 0 -1rem;
      border-radius: 0;
    }
  }

  .author {
    width: calc(100% + 2rem);
    margin: 2rem -1rem 0 -1rem;
    flex-direction: column;
    justify-content: flex-start;
  }

  .author--photo {
    align-items: center;
  }

  .author--description {
    margin-top: 2rem;
    padding-left: 0;
    text-align: justify;
  }

  footer {
    margin: 0 -1rem -3rem -1rem;
  }
}

