$author-background: #FDDCA8;
$author-color: #444;
$footer-background: #404142;
$footer-color: #7B797B;

$dark-theme: "dark-theme";
$light-theme: "light-theme";

$background-color: "background-color";
$text-color: "text-color";
$headline-text-color: "headline-text-color";
$light-text-color: "light-text-color";
$highlight-color: "highlight-color";
$light-highlight-color: "light-highlight-color";
$border: "border";

$list-background: "list-background";
$list-color: "list-color";

$code-color: "code-color";

$themes: (
  $light-theme: (
    $background-color: #ffffff,
    $text-color: #444,
    $headline-text-color: #444,
    $light-text-color: #999,

    $highlight-color: #D8001A,
    $light-highlight-color: #CFCFCF,

    $border: #D9D9DA,

    $list-background: #fff,
    $list-color: #444,

    $code-color: #f3f3f3,
  ),
  $dark-theme: (
    $background-color: #111,
    $text-color: #FEFEFE,
    $headline-text-color: #CCC,
    $light-text-color: #AAA,

    $highlight-color: #D8001A,
    $light-highlight-color: #CFCFCF,

    $border: #D9D9DA,
    
    $list-background: #111,
    $list-color: #fff,

    $code-color: #1f1f1f,
  )
);

@mixin themeProperty($theme, $property, $color, $additionalPropertiesPositionIsFront, $additionalProperties) {
  @if $additionalPropertiesPositionIsFront {
    #{$property}: unquote($additionalProperties + ' ' + map-get($theme, $color));
  } @else {
    #{$property}: unquote(map-get($theme, $color) + ' ' + $additionalProperties);
  }
}

@mixin theme($property, $color, $additionalPropertiesPositionIsFront: false, $additionalProperties: '') {
  $light: map-get($themes, $light-theme);
  $dark: map-get($themes, $dark-theme);
  
  @include themeProperty($light, $property, $color, $additionalPropertiesPositionIsFront, $additionalProperties);

  @media (prefers-color-scheme: dark) {
    @include themeProperty($dark, $property, $color, $additionalPropertiesPositionIsFront, $additionalProperties);
  }
}