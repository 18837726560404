.welcome {
  display: flex;
  line-height: 1.8rem;
  justify-content: center;
  @include theme('color', $text-color);
}

.welcome--portrait {
  max-width: 100%;
}

.welcome--description {
  width: 34rem;
  padding-top: 3.2rem;
  margin-left: 3rem;
}

.welcome--job-title {
  font-weight: bold;
  @include theme('color', $headline-text-color);
}

.welcome--name {
  font-size: 2rem;
  font-weight: bold;
  padding: 0 0 8px 0;
  @include theme('color', $headline-text-color);
}

.welcome--contact {
  margin: 0.5rem 0 1rem 0;
}

.welcome--contact--item {
  i {
    @include theme('color', $light-text-color);
    padding-right: 0.5rem;
  }
  a {
    @include theme('color', $text-color);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
  white-space: nowrap;
  padding-right: 2rem;
}

.welcome--links {
  margin: 2rem 0 2rem 0;
  a {
    @include theme('color', $light-text-color);
    margin-right: 1rem;
    font-size: 0;
    text-decoration: none;
    i {
      font-size: 2rem;
    }
  }
}

.welcome--separator {
  border-top: 1px solid;
  width: 50%;
  @include theme('color', $light-highlight-color);
  margin: 2rem 0;
}

.welcome--about-me {
  @include theme('color', $text-color);
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: 200;

  a {
    @include theme('color', $text-color);
  }
}

@media (max-width: 50rem) {
  .welcome {
    flex-direction: column;
  }

  .welcome--photo {
    text-align: center;
  }


  .welcome--description {
    width: 100%;
    padding-top: 2rem;
    margin-left: 0;
  }

  .welcome--contact {
    display: flex;
    flex-direction: column;
  }

  .welcome--contact--item {
    white-space: normal;
    padding-right: 0;
    display: grid;
    grid-template-columns: 1.8rem 1fr;
    margin-bottom: 0.2rem;

    i {
      padding-top: 0.3rem;
    }

  }
}