.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 58rem;
  margin: 0 auto;
}

.header--gravatar {
  img {
    width: 3rem;
  }
}

.header--title {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  a {
    text-decoration: none;
    @include theme('color', $text-color);
    text-transform: uppercase;
  }
}

.header--menu {
  flex-grow: 1;
  justify-content: flex-end;
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  a {
    margin-left: 2rem;
    @include theme('color', $text-color);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: 35rem) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
  }

  .header--menu a {
    margin-left: 0;
    margin-right: 2rem;
  }

  .header--title {
    margin: 1rem 0;
  }
}