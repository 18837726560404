.highlight .hll {
  background-color: #d6d6d6;
}

.highlight {
  background: #ffffff;
  color: #4d4d4c;
}

.highlight .c {
  color: #8e908c;
}

/* Comment */
.highlight .err {
  color: #c82829;
}

/* Error */
.highlight .k {
  color: #8959a8;
}

/* Keyword */
.highlight .l {
  color: #f5871f;
}

/* Literal */
.highlight .n {
  color: #4d4d4c;
}

/* Name */
.highlight .o {
  color: #3e999f;
}

/* Operator */
.highlight .p {
  color: #4d4d4c;
}

/* Punctuation */
.highlight .cm {
  color: #8e908c;
}

/* Comment.Multiline */
.highlight .cp {
  color: #8e908c;
}

/* Comment.Preproc */
.highlight .c1 {
  color: #8e908c;
}

/* Comment.Single */
.highlight .cs {
  color: #8e908c;
}

/* Comment.Special */
.highlight .gd {
  color: #c82829;
}

/* Generic.Deleted */
.highlight .ge {
  font-style: italic;
}

/* Generic.Emph */
.highlight .gh {
  color: #4d4d4c;
  font-weight: bold;
}

/* Generic.Heading */
.highlight .gi {
  color: #718c00;
}

/* Generic.Inserted */
.highlight .gp {
  color: #8e908c;
  font-weight: bold;
}

/* Generic.Prompt */
.highlight .gs {
  font-weight: bold;
}

/* Generic.Strong */
.highlight .gu {
  color: #3e999f;
  font-weight: bold;
}

/* Generic.Subheading */
.highlight .kc {
  color: #8959a8;
}

/* Keyword.Constant */
.highlight .kd {
  color: #8959a8;
}

/* Keyword.Declaration */
.highlight .kn {
  color: #3e999f;
}

/* Keyword.Namespace */
.highlight .kp {
  color: #8959a8;
}

/* Keyword.Pseudo */
.highlight .kr {
  color: #8959a8;
}

/* Keyword.Reserved */
.highlight .kt {
  color: #eab700;
}

/* Keyword.Type */
.highlight .ld {
  color: #718c00;
}

/* Literal.Date */
.highlight .m {
  color: #f5871f;
}

/* Literal.Number */
.highlight .s {
  color: #718c00;
}

/* Literal.String */
.highlight .na {
  color: #4271ae;
}

/* Name.Attribute */
.highlight .nb {
  color: #4d4d4c;
}

/* Name.Builtin */
.highlight .nc {
  color: #eab700;
}

/* Name.Class */
.highlight .no {
  color: #c82829;
}

/* Name.Constant */
.highlight .nd {
  color: #3e999f;
}

/* Name.Decorator */
.highlight .ni {
  color: #4d4d4c;
}

/* Name.Entity */
.highlight .ne {
  color: #c82829;
}

/* Name.Exception */
.highlight .nf {
  color: #4271ae;
}

/* Name.Function */
.highlight .nl {
  color: #4d4d4c;
}

/* Name.Label */
.highlight .nn {
  color: #eab700;
}

/* Name.Namespace */
.highlight .nx {
  color: #4271ae;
}

/* Name.Other */
.highlight .py {
  color: #4d4d4c;
}

/* Name.Property */
.highlight .nt {
  color: #3e999f;
}

/* Name.Tag */
.highlight .nv {
  color: #c82829;
}

/* Name.Variable */
.highlight .ow {
  color: #3e999f;
}

/* Operator.Word */
.highlight .w {
  color: #4d4d4c;
}

/* Text.Whitespace */
.highlight .mf {
  color: #f5871f;
}

/* Literal.Number.Float */
.highlight .mh {
  color: #f5871f;
}

/* Literal.Number.Hex */
.highlight .mi {
  color: #f5871f;
}

/* Literal.Number.Integer */
.highlight .mo {
  color: #f5871f;
}

/* Literal.Number.Oct */
.highlight .sb {
  color: #718c00;
}

/* Literal.String.Backtick */
.highlight .sc {
  color: #4d4d4c;
}

/* Literal.String.Char */
.highlight .sd {
  color: #8e908c;
}

/* Literal.String.Doc */
.highlight .s2 {
  color: #718c00;
}

/* Literal.String.Double */
.highlight .se {
  color: #f5871f;
}

/* Literal.String.Escape */
.highlight .sh {
  color: #718c00;
}

/* Literal.String.Heredoc */
.highlight .si {
  color: #f5871f;
}

/* Literal.String.Interpol */
.highlight .sx {
  color: #718c00;
}

/* Literal.String.Other */
.highlight .sr {
  color: #718c00;
}

/* Literal.String.Regex */
.highlight .s1 {
  color: #718c00;
}

/* Literal.String.Single */
.highlight .ss {
  color: #718c00;
}

/* Literal.String.Symbol */
.highlight .bp {
  color: #4d4d4c;
}

/* Name.Builtin.Pseudo */
.highlight .vc {
  color: #c82829;
}

/* Name.Variable.Class */
.highlight .vg {
  color: #c82829;
}

/* Name.Variable.Global */
.highlight .vi {
  color: #c82829;
}

/* Name.Variable.Instance */
.highlight .il {
  color: #f5871f;
}

/* Literal.Number.Integer.Long */

@media (prefers-color-scheme: dark) {
  .highlight {
    background: #272822;
    color: #f8f8f2;
  }

  .highlight pre {
    background-color: #272822;
  }

  .highlight .hll {
    background-color: #272822;
  }

  .highlight .c {
    color: #75715e;
  }

  /* Comment */
  .highlight .err {
    color: #960050;
  }

  /* Error */
  .highlight .k {
    color: #66d9ef;
  }

  /* Keyword */
  .highlight .l {
    color: #ae81ff;
  }

  /* Literal */
  .highlight .n {
    color: #f8f8f2;
  }

  /* Name */
  .highlight .o {
    color: #f92672;
  }

  /* Operator */
  .highlight .p {
    color: #f8f8f2;
  }

  /* Punctuation */
  .highlight .cm {
    color: #75715e;
  }

  /* Comment.Multiline */
  .highlight .cp {
    color: #75715e;
  }

  /* Comment.Preproc */
  .highlight .c1 {
    color: #75715e;
  }

  /* Comment.Single */
  .highlight .cs {
    color: #75715e;
  }

  /* Comment.Special */
  .highlight .ge {
    font-style: italic;
  }

  /* Generic.Emph */
  .highlight .gs {
    font-weight: bold;
  }

  /* Generic.Strong */
  .highlight .kc {
    color: #66d9ef;
  }

  /* Keyword.Constant */
  .highlight .kd {
    color: #66d9ef;
  }

  /* Keyword.Declaration */
  .highlight .kn {
    color: #f92672;
  }

  /* Keyword.Namespace */
  .highlight .kp {
    color: #66d9ef;
  }

  /* Keyword.Pseudo */
  .highlight .kr {
    color: #66d9ef;
  }

  /* Keyword.Reserved */
  .highlight .kt {
    color: #66d9ef;
  }

  /* Keyword.Type */
  .highlight .ld {
    color: #e6db74;
  }

  /* Literal.Date */
  .highlight .m {
    color: #ae81ff;
  }

  /* Literal.Number */
  .highlight .s {
    color: #e6db74;
  }

  /* Literal.String */
  .highlight .na {
    color: #a6e22e;
  }

  /* Name.Attribute */
  .highlight .nb {
    color: #f8f8f2;
  }

  /* Name.Builtin */
  .highlight .nc {
    color: #a6e22e;
  }

  /* Name.Class */
  .highlight .no {
    color: #66d9ef;
  }

  /* Name.Constant */
  .highlight .nd {
    color: #a6e22e;
  }

  /* Name.Decorator */
  .highlight .ni {
    color: #f8f8f2;
  }

  /* Name.Entity */
  .highlight .ne {
    color: #a6e22e;
  }

  /* Name.Exception */
  .highlight .nf {
    color: #a6e22e;
  }

  /* Name.Function */
  .highlight .nl {
    color: #f8f8f2;
  }

  /* Name.Label */
  .highlight .nn {
    color: #f8f8f2;
  }

  /* Name.Namespace */
  .highlight .nx {
    color: #a6e22e;
  }

  /* Name.Other */
  .highlight .py {
    color: #f8f8f2;
  }

  /* Name.Property */
  .highlight .nt {
    color: #f92672;
  }

  /* Name.Tag */
  .highlight .nv {
    color: #f8f8f2;
  }

  /* Name.Variable */
  .highlight .ow {
    color: #f92672;
  }

  /* Operator.Word */
  .highlight .w {
    color: #f8f8f2;
  }

  /* Text.Whitespace */
  .highlight .mf {
    color: #ae81ff;
  }

  /* Literal.Number.Float */
  .highlight .mh {
    color: #ae81ff;
  }

  /* Literal.Number.Hex */
  .highlight .mi {
    color: #ae81ff;
  }

  /* Literal.Number.Integer */
  .highlight .mo {
    color: #ae81ff;
  }

  /* Literal.Number.Oct */
  .highlight .sb {
    color: #e6db74;
  }

  /* Literal.String.Backtick */
  .highlight .sc {
    color: #e6db74;
  }

  /* Literal.String.Char */
  .highlight .sd {
    color: #e6db74;
  }

  /* Literal.String.Doc */
  .highlight .s2 {
    color: #e6db74;
  }

  /* Literal.String.Double */
  .highlight .se {
    color: #ae81ff;
  }

  /* Literal.String.Escape */
  .highlight .sh {
    color: #e6db74;
  }

  /* Literal.String.Heredoc */
  .highlight .si {
    color: #e6db74;
  }

  /* Literal.String.Interpol */
  .highlight .sx {
    color: #e6db74;
  }

  /* Literal.String.Other */
  .highlight .sr {
    color: #e6db74;
  }

  /* Literal.String.Regex */
  .highlight .s1 {
    color: #e6db74;
  }

  /* Literal.String.Single */
  .highlight .ss {
    color: #e6db74;
  }

  /* Literal.String.Symbol */
  .highlight .bp {
    color: #f8f8f2;
  }

  /* Name.Builtin.Pseudo */
  .highlight .vc {
    color: #f8f8f2;
  }

  /* Name.Variable.Class */
  .highlight .vg {
    color: #f8f8f2;
  }

  /* Name.Variable.Global */
  .highlight .vi {
    color: #f8f8f2;
  }

  /* Name.Variable.Instance */
  .highlight .il {
    color: #ae81ff;
  }

  /* Literal.Number.Integer.Long */

  .highlight .gh {}

  /* Generic Heading & Diff Header */
  .highlight .gu {
    color: #75715e;
  }

  /* Generic.Subheading & Diff Unified/Comment? */
  .highlight .gd {
    color: #f92672;
  }

  /* Generic.Deleted & Diff Deleted */
  .highlight .gi {
    color: #a6e22e;
  }

  /* Generic.Inserted & Diff Inserted */
}