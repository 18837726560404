/* open-sans-condensed-300 - latin */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/open-sans-condensed-v14-latin-300.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Condensed Light'), local('OpenSansCondensed-Light'),
    url('/assets/fonts/open-sans-condensed-v14-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/assets/fonts/open-sans-condensed-v14-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/assets/fonts/open-sans-condensed-v14-latin-300.woff') format('woff'),
    /* Modern Browsers */
    url('/assets/fonts/open-sans-condensed-v14-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/assets/fonts/open-sans-condensed-v14-latin-300.svg#OpenSansCondensed') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/open-sans-v17-latin-300.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'),
    url('/assets/fonts/open-sans-v17-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/assets/fonts/open-sans-v17-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/assets/fonts/open-sans-v17-latin-300.woff') format('woff'),
    /* Modern Browsers */
    url('/assets/fonts/open-sans-v17-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/assets/fonts/open-sans-v17-latin-300.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url('/assets/fonts/open-sans-v17-latin-300italic.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
    url('/assets/fonts/open-sans-v17-latin-300italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/assets/fonts/open-sans-v17-latin-300italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/assets/fonts/open-sans-v17-latin-300italic.woff') format('woff'),
    /* Modern Browsers */
    url('/assets/fonts/open-sans-v17-latin-300italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/assets/fonts/open-sans-v17-latin-300italic.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/open-sans-v17-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('/assets/fonts/open-sans-v17-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/assets/fonts/open-sans-v17-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/assets/fonts/open-sans-v17-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('/assets/fonts/open-sans-v17-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/assets/fonts/open-sans-v17-latin-regular.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/open-sans-v17-latin-italic.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
    url('/assets/fonts/open-sans-v17-latin-italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/assets/fonts/open-sans-v17-latin-italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/assets/fonts/open-sans-v17-latin-italic.woff') format('woff'),
    /* Modern Browsers */
    url('/assets/fonts/open-sans-v17-latin-italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/assets/fonts/open-sans-v17-latin-italic.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: url("/assets/fonts/opensans-extrabold-webfont.eot");
  src: url("/assets/fonts/opensans-extrabold-webfont.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/opensans-extrabold-webfont.woff") format("woff"),
    url("/assets/fonts/opensans-extrabold-webfont.ttf") format("truetype"),
    url("/assets/fonts/opensans-extrabold-webfont.svg#open_sansextrabold") format("svg");
}

/* lora-regular - latin */
@font-face {
  font-family: "Atkinson Hyperlegible";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/atkinson-hyperlegible-regular-102a.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url("/assets/fonts/atkinson-hyperlegible-regular-102.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Atkinson Hyperlegible";
  font-style: normal;
  font-weight: 800;
  src: url("/assets/fonts/atkinson-hyperlegible-bold-102a.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url("/assets/fonts/atkinson-hyperlegible-bold-102.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Atkinson Hyperlegible";
  font-style: italic;
  font-weight: 400;
  src: url("/assets/fonts/atkinson-hyperlegible-italic-102a.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url("/assets/fonts/atkinson-hyperlegible-italic-102.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Atkinson Hyperlegible";
  font-style: italic;
  font-weight: 800;
  src: url("/assets/fonts/atkinson-hyperlegible-bolditalic-102a.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url("/assets/fonts/atkinson-hyperlegible-bolditalic-102.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}